import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { useState } from "react";
import Feature from './feature';
import SectionTitle from './section-title';

const FeaturesAnimated = ({
    features,
    title,
    description,
    featureListTitleCss,
    featureTitleCss,
    featureContentCss,
    featureCss,
    titleTag
}) => {
    let [hoveredIndex, setHoveredIndex] = useState(null);

    return (
        <>
            {(title || description) && (
                <SectionTitle title={title} description={description} className={`w-full section-title ${featureListTitleCss}`}></SectionTitle>
            )}
            <div className="grid-animated grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
                {features.map((f, idx) => (
                    <div key={`f-${idx}-${f.id}`}
                        className="relative group  block p-8 h-full w-full"
                        onMouseEnter={() => setHoveredIndex(idx)}
                        onMouseLeave={() => setHoveredIndex(null)}
                    >
                        <AnimatePresence>
                            {hoveredIndex === idx && (
                                <motion.span
                                    className="absolute inset-0 h-full w-full bg-white bg-opacity-5 block rounded-3xl"
                                    layoutId="hoverBackground"
                                    initial={{ opacity: 0 }}
                                    animate={{
                                        opacity: 1,
                                        transition: { duration: 0.15 },
                                    }}
                                    exit={{
                                        opacity: 0,
                                        transition: { duration: 0.15, delay: 0.2 },
                                    }}
                                />
                            )}
                        </AnimatePresence>
                        <Feature
                            key={f.id}
                            feature={f}
                            featureCss={featureCss}
                            featureTitleCss={featureTitleCss}
                            featureContentCss={featureContentCss}
                            titleTag={titleTag}
                        ></Feature>
                    </div>
                ))}
            </div>
        </>
    );
};
export default FeaturesAnimated;